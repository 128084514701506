


import React from "react";
import {
  Box,
  Heading,
  VStack,
  Flex,
  Divider,
  Button,
  Spacer,
  createStandaloneToast,
  Text,
  Skeleton,
  Progress,
  IconButton,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  ModalFooter
} from '@chakra-ui/react';

import { AreaChart, GridlineSeries, Gridline, BarSparklineChart } from 'reaviz';
import { BellIcon } from '@chakra-ui/icons'
import { extendTheme } from "@chakra-ui/react"

  const theme = extendTheme({
    fonts: {
        0: "Gilroy",
        1: "Inter",
    },
    colors: {
       secondary: {
        50: "#FFFFF0",
        100: "#FEFCBF",
        200: "#FAF089",
        300: "#F6E05E",
        400: "#ECC94B",
        500: "#D69E2E",
        600: "#B7791F",
        700: "#975A16",
        800: "#744210",
        900: "#5F370E"
       }
    }
  })

class Home extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      location: {
        lat: null,
        long: null,
        hasFetched: false,
        hasFetching: false,
        supported: false,
        error: false,
      },
      data: {
        data: [],
        hasFetched: false,
        hasFetching: true,
        error: false,
      },
      utilities: {
        hasModelOpen: false,
        hasSubscribed: false,
      },
      notification: {
        hasSubscribed: localStorage.getItem("hasSubscribed")?localStorage.getItem("hasSubscribed"):false,
        station: null,
        isOpenNotificationIntro: false,
        loading: false,
        proceed: false,
      },
      drawer: {
        isOpen: false,
        stationName: null,
        hasDataFetched: false,
        hasDataFetching: true,
        aqi: null,
        aqiHistory: [],
        suggestions: [],
        co: null,
        coHistory: [],
        co2: null,
        co2History: [],
        humidity: null,
        humidityHistory: null,
        temprature: null,
        tempratureHistory: [],
        o2: null,
        o2History: [],
        uv: null,
        uvHistory: [],
        noise: null,
        noiseHistory: [],
        no: null,
        noHistory: [],
        no2: null,
        no2History: [],
      }
    };

    if (!navigator.geolocation) {
      this.toast({
        title: 'Update your browser',
        description: 'Geo Location is not supported by your browser.',
        status: 'error',
        isClosable: true,
        duration: 9000,
      });
      this.setState(prevState => ({
        location: {
          ...prevState.location, // Prev copy all state
          supported: false,
        },
      }));
    }


  }

  componentDidMount = async () => {
    if (typeof Number.prototype.toRad === 'undefined') {
      // eslint-disable-next-line no-extend-native
      Number.prototype.toRad = function () {
        return (this * Math.PI) / 180;
      };
    }
    let res = await fetch(
      'https://rajkotaqi-raven.vercel.app/v0/stations/'
    );
    let resData = await res.json();

    this.setState(prevState => ({
      ...prevState.data,
      data: {
        data: resData.data,
        hasFetching: true,
        hasFetched: false,
        error: false,
      },
    }));

    if (localStorage.getItem('lat') && localStorage.getItem('long')) {

      this.setState(prevState => ({
        location: {
          ...prevState.location,
          lat: localStorage.getItem('lat'),
          long: localStorage.getItem('long'),
          hasFetched: true,
          hasFetching: false,
        },
      }));

      this.nearLocation();

    }
    else {
      this.setState(prevState => ({
        location: {
          ...prevState.location,
          hasFetched: false,
          hasFetching: false,
          supported: true,
        },
        data: {
          ...prevState.data,
          hasFetched: true,
          hasFetching: false
        }
      }));
    }

    
  };

  distance(position1, position2) {
    var lat1 = Number(position1.lat);
    var lat2 = Number(position2.lat);
    var lon1 = Number(position1.long);
    var lon2 = Number(position2.long);
    var R = 6371000; // metres
    var φ1 = lat1.toRad();
    var φ2 = lat2.toRad();
    var Δφ = (lat2 - lat1).toRad();
    var Δλ = (lon2 - lon1).toRad();

    var a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    var d = R * c;
    return d;
  }

  urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, '+')
      .replace(/_/g, '/');
  
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);
  
    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  subscribe = async(stationId) => {
    // Information about notification
    this.setState(prevState => ({
      notification: {
        ...prevState.notification,
        isOpenNotificationIntro: true,
        station: stationId,
      }
    }));
  }

  sendRequest(url, body) {
    return fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json'
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(body) // body data type must match "Content-Type" header
    });
  }

  askPermission = async() => {
    this.setState(prevState => ({
      notification: {
        ...prevState.notification,
        loading: true,
      }
    }));
    // Ask for notification
    navigator.serviceWorker.ready.then(reg => {
      reg.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: this.urlBase64ToUint8Array("BErRKsC_kupqKQJ6rdP3r2pGCkGqnAkzMJz7__UmIVv_ojj_2Ltv8OvFgHaPzKSiXS7_OTJAPK2MjAd7vKMWsjM")
      }).then(async(sub) => {
        let res = await this.sendRequest("https://rajkotaqi-raven.vercel.app/v0/notifications/subscribe/", {sub: sub, station: this.state.notification.station});
        let subResult = await res.json();
        if(subResult.success) {
          navigator.vibrate(200);
          this.toast({
            title: 'Thank you',
            description: 'You have subscribed successfully.',
            status: 'success',
            isClosable: true,
            duration: 9000,
          });
          localStorage.setItem("hasSubscribed", true);
          this.setState(prevState => ({
            notification: {
              ...prevState.notification,
              loading: false,
              isOpenNotificationIntro : false,
              hasSubscribed: true
            }
          }));
        }
        else {
          this.toast({
            title: 'Something went wrong!',
            description: 'Try again after sometime.',
            status: 'error',
            isClosable: true,
            duration: 9000,
          });
          this.setState(prevState => ({
            notification: {
              ...prevState.notification,
              loading: false,
              isOpenNotificationIntro: false,
              hasSubscribed: false
            }
          }));
        }
        
      }).catch(err => {
        this.toast({
          title: 'You deny notification permission',
          description: 'Change notification permission by reseting site data',
          status: 'error',
          isClosable: true,
          duration: 9000,
        });
        this.setState(prevState => ({
          notification: {
            ...prevState.notification,
            loading: false,
            isOpenNotificationIntro: false
          }
        }));
      })
    })
    .catch(e => {
      window.location.reload();
      this.toast({
        title: 'Update your browser',
        description: 'Notification is not supported by your browser.',
        status: 'error',
        isClosable: true,
        duration: 9000,
      });
      this.setState(prevState => ({
        notification: {
          ...prevState.notification,
          loading: false,
          isOpenNotificationIntro: false
        }
      }));
    });
  }

  nearLocation = () => {
    let myLocation = {
      lat: this.state.location.lat,
      long: this.state.location.long,
    };

    let closestDistance = this.distance(this.state.data.data[0], myLocation);
    let closestIndex = 0;

    for(let i=1; i<this.state.data.data.length; i+=1) {
      if(this.distance(this.state.data.data[i], myLocation) < closestDistance) {
        closestIndex = i;
        closestDistance = this.distance(this.state.data.data[i], myLocation);
      }
    } 

    let locData = this.state.data.data;
    let item = locData.splice(closestIndex, 1);
    item[0].allowUpdate = true;
    locData.unshift(item[0]);

    this.setState(prevState => ({
      ...prevState.data,
      data: {
        data: locData,
        hasFetching: false,
        hasFetched: true,
        error: false,
      },
    }));

  }

  onCloseNotificationIntro = (e) => {
    this.setState(prevState => ({
      notification: {
        ...prevState.notification,
        isOpenNotificationIntro: false
      }
    }));
  }

  toast = createStandaloneToast();

  /**
   * Execute when fetch location button pressed
   * @param {Event} e
   */
  async fetchLocation(e) {
    this.setState(prevState => ({
      location: {
        ...prevState.location, // Prev copy all state
        hasFetching: true,
      },
    }));

    navigator.geolocation.getCurrentPosition((success => {
      
      localStorage.setItem('lat', success.coords.latitude);
        localStorage.setItem('long', success.coords.longitude);
        this.setState(prevState => ({
          location: {
            ...prevState.localStorage,
            lat: success.coords.latitude,
            long: success.coords.longitude,
            hasFetched: true,
          },
        }));

        this.nearLocation();
      
    }), (error => {
      this.setState(prevState => ({
        location: {
          ...prevState.location,
          error: true,
          hasFetched: true,
        },
      }));
        this.toast({
          title: 'You deny location permission',
          description: 'You can change permission settings by reset site data.',
          status: 'warning',
          isClosable: true,
          duration: 9000,
        });
      }));
    }

  /**
   * Close help and privacy model
   * @param {Event} e
   */
  modelClose = e => {
    this.setState(prevState => ({
      utilities: {
        ...prevState.utilities,
        hasModelOpen: false,
      },
    }));
  };

  /**
   * Executes when help button pressed
   * @param {Event} e
   */
  modelOpen = e => {
    this.setState(prevState => ({
      utilities: {
        ...prevState.utilities,
        hasModelOpen: true,
      },
    }));
  };

  // DRAWER
  onCloseDetailWeather = (e) => {
    this.setState(prevState => ({
      drawer: {
        ...prevState.drawer,
        isOpen: false,
        id: null,
        stationName: null,
        hasFetched: false,
        hasFetching: true,
      },
    }));
  }


  openDrawer = async (data) => {
    this.setState(prevState => ({
      drawer: {
        ...prevState.drawer,
        isOpen: true,
        id: data.id,
        stationName: data.name,
        hasFetched: false,
        hasFetching: true,
      },
    }));

    let res = await fetch(
      `https://rajkotaqi-raven.vercel.app/v0/stations/${data.id}`
    );
    let resData = await res.json();
    let lts = resData.data.latest_param_value;
    let ltsHistory = resData.data.hourly_param_concs;
    let aqiLen = 24;
    let coLen = 24;
    let humidityLen = 24;
    let co2Len = 24;
    let o2Len = 24;
    let noLen = 24;
    let no2Len = 24;
    let tempratureLen = 24;
    let so2Len = 24;
    let uvLen = 24;
    let noiseLen = 24;
    

    if(resData.success) {

        this.setState(prevState => ({
          drawer: {
            ...prevState.drawer,
            aqi: resData.data.aqi,
            suggestions: resData.data.suggestions,
            aqiHistory: resData.data.hourly_aqis.map(data => {
              return {
                key: new Date(
                  new Date().setHours(new Date().getHours() - aqiLen--)
                ),
                data: Number(data) || 0,
              };
            }),
            co: lts.co.toString().substr(0, 5),
            coHistory: ltsHistory.co.map(data => {
              return {
                key: new Date(
                  new Date().setHours(new Date().getHours() - coLen--)
                ),
                data: Number(data) || 0,
              };
            }),
            humidity: lts.humidity.toString().substr(0, 5),
            humidityHistory: ltsHistory.humidity.map(data => {
              return {
                key: new Date(
                  new Date().setHours(new Date().getHours() - humidityLen--)
                ),
                data: Number(data) || 0,
              };
            }),
            co2: lts.co2.toString().substr(0, 5),
            co2History: ltsHistory.co2.map(data => {
              return {
                key: new Date(
                  new Date().setHours(new Date().getHours() - co2Len--)
                ),
                data: Number(data) || 0,
              };
            }),
            o2: lts.o2.toString().substr(0, 5),
            o2History: ltsHistory.o2.map(data => {
              return {
                key: new Date(
                  new Date().setHours(new Date().getHours() - o2Len--)
                ),
                data: Number(data) || 0,
              };
            }),
            no: lts.no.toString().substr(0, 5),
            noHistory: ltsHistory.no.map(data => {
              return {
                key: new Date(
                  new Date().setHours(new Date().getHours() - noLen--)
                ),
                data: Number(data) || 0,
              };
            }),
            no2: lts.no2.toString().substr(0, 5),
            no2History: ltsHistory.no2.map(data => {
              return {
                key: new Date(
                  new Date().setHours(new Date().getHours() - no2Len--)
                ),
                data: Number(data) || 0,
              };
            }),
            temprature: lts.temperature.toString().substr(0, 5),
            tempratureHistory: ltsHistory.temperature.map(data => {
              return {
                key: new Date(
                  new Date().setHours(new Date().getHours() - tempratureLen--)
                ),
                data: Number(data) || 0,
              };
            }),
            so2: lts.so2.toString().substr(0, 5),
            so2History: ltsHistory.so2.map(data => {
              return {
                key: new Date(
                  new Date().setHours(new Date().getHours() - so2Len--)
                ),
                data: Number(data) || 0,
              };
            }),
            uv: lts.uv.toString().substr(0, 5),
            uvHistory: ltsHistory.uv.map(data => {
              return {
                key: new Date(
                  new Date().setHours(new Date().getHours() - uvLen--)
                ),
                data: Number(data) || 0,
              };
            }),
            noise: lts.noise.toString().substr(0, 5),
            noiseHistory: ltsHistory.noise.map(data => {
              return {
                key: new Date(
                  new Date().setHours(new Date().getHours() - noiseLen--)
                ),
                data: Number(data) || 0,
              };
            }),
            
            hasFetched: true,
            hasFetching: false,
          },
        }));

    }

  }

  render() {
    return (
      <div style={{ width: '100%' }}>
        <Box mx="12px">
          <VStack spacing="2" px="12px" py="12">
            <Box textAlign="center">
              <Heading
                as="h3"
                size="xl"
                fontFamily={theme.fonts[0]}
                textColor={theme.colors.blue[800]}
              >
                Rajkot AQI
              </Heading>
            </Box>
            <Text
              textAlign="center"
              py="1"
              fontFamily={theme.fonts[1]}
              size="16px"
              textColor={theme.colors.gray[600]}
            >
              Explore Rajkot Air Quality Index(AQI), CO, CO<sub>2</sub>, NO, NO
              <sub>2</sub>, Temperature, and more...
            </Text>
          </VStack>
          {this.state.location.supported && !this.state.location.hasFetched ? (
            <VStack>
              <Box
                bgColor={theme.colors.secondary[600]}
                w={['100%', '33%']}
                px="14px"
                borderRadius={theme.radii.md}
              >
                <Flex h="56px" align="center">
                  <Box textColor="white" fontFamily={theme.fonts[1]}>
                    Near Location AQI
                  </Box>
                  <Spacer />
                  <Button
                    onClick={e => this.fetchLocation(e)}
                    isLoading={this.state.location.hasFetching ? true : false}
                    colorScheme={theme.colors.secondary}
                    variant="outline"
                    textColor="#fff"
                  >
                    Enable Location
                  </Button>
                </Flex>
              </Box>
            </VStack>
          ) : null}
          {this.state.data.hasFetching ? (
            <Box px={['14px', '72px']} marginTop="22px">
              <Text
                display={['block', 'none']}
                fontFamily={theme.fonts[0]}
                textColor={theme.colors.secondary[600]}
              >
                AQI STATIONS
              </Text>

              <Flex marginTop="12px" wrap="wrap" display={['block', 'none']}>
                <Skeleton
                  height="200px"
                  width={['100%', '200px']}
                  marginLeft={['0', '0']}
                  marginTop={['18px', '0']}
                />
                <Skeleton
                  height="200px"
                  width={['100%', '200px']}
                  marginLeft={['0', '18px']}
                  marginTop={['18px', '0']}
                />
                <Skeleton
                  height="200px"
                  width={['100%', '200px']}
                  marginLeft={['0', '18px']}
                  marginTop={['18px', '0']}
                />
                <Spacer height="24px" />
              </Flex>

              <Box
                margin="100px auto"
                width="200px"
                display={['none', 'block']}
              >
                <Progress size="xs" isIndeterminate colorScheme="blue" />
              </Box>
            </Box>
          ) : null}


          {
            this.state.notification.isOpenNotificationIntro?(
              <>
                <Modal isOpen={this.state.notification.isOpenNotificationIntro} onClose={(e) => this.onCloseNotificationIntro()}>
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader color={theme.colors.blue[800]}>Notification Updates</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody fontFamily={theme.fonts[1]}>
                    <Box py="4px">
                    We will send you daily AQI notifications of your nearby location at 
                    </Box>
                    <Divider orientation="horizontal" />
                    <Box py="4px">
                    🕘 09:00 AM (IST)
                    </Box>
                    <Divider orientation="horizontal" />
                    <Box py="4px">
                    🕛 12:00 PM (IST)
                    </Box>
                    <Divider orientation="horizontal" />
                    <Box py="4px">
                    🕒 03:00 PM (IST)
                    </Box>
                    <Divider orientation="horizontal" />
                    <Box py="4px">
                    🕕 06:00 PM (IST)
                    </Box>
                    </ModalBody>

                    <ModalFooter>
                      <Button variant="ghost" onClick={(e) => this.onCloseNotificationIntro()}>
                        No, Close
                      </Button>
                      <Button colorScheme="blue" mr={3} onClick={(e) => this.askPermission()} isLoading={this.state.notification.loading}>Okay, Proceed</Button>
                    </ModalFooter>
                  </ModalContent>
                </Modal>
              </>
            ):null
          }

          {!this.state.data.hasFetching && this.state.data.hasFetched ? (
            <Box px={['14px', '72px']} marginTop="22px">
              <Flex marginTop="12px" wrap="wrap" justifyContent="space-evenly">
                {this.state.data.data.map(station => {
                  return (
                    <Box
                      key={station.id}
                      width={['100%', '300px']}
                      borderWidth="1px"
                      borderRadius="lg"
                      overflow="hidden"
                      textAlign="left"
                      justifyContent="left"
                      marginTop="12px"
                    >
                      <Box px="4" py="4">
                        <VStack spacing={4} align="stretch">
                          {
                            station.allowUpdate?
                            (
                              <Flex alignItems="center">
                                <Text
                                  fontFamily={theme.fonts[0]}
                                  textColor={theme.colors.blue[800]}
                                  textAlign="left"
                                >
                                  {station.name}
                                </Text>
                                <Spacer />

                                {
                                  !this.state.notification.hasSubscribed?(
                                    <IconButton
                                      colorScheme="white"
                                      aria-label="Subscribe for updates"
                                      size="lg"
                                      icon={<BellIcon boxSize="28px" color="grey" onClick={(e) => this.subscribe(station.name)}/>}
                                    />
                                  ):null
                                }
                              </Flex>
                            ):
                            (
                              <Text
                                fontFamily={theme.fonts[0]}
                                textColor={theme.colors.blue[800]}
                                textAlign="left"
                              >
                                {station.name}
                              </Text>
                            )
                          }
                          <Flex>
                            <Text
                              fontFamily={theme.fonts[0]}
                              textColor={theme.colors.blue[800]}
                              fontSize="42px"
                            >
                              {station.aqi <= 100
                                ? '😇'
                                : station.aqi > 100
                                ? '😫'
                                : '😷'}{' '}
                              {station.aqi}{' '}
                            </Text>
                            <VStack
                              marginTop="0"
                              marginLeft="12px"
                              align="left"
                            >
                              <Text
                                fontSize="18px"
                                fontFamily={theme.fonts[0]}
                                textColor={theme.colors.blue[800]}
                                paddingTop="6px"
                                textAlign="left"
                              >
                                AQI
                              </Text>
                              <Box
                                fontSize="14px"
                                fontFamily={theme.fonts[1]}
                                textColor={theme.colors.blue[800]}
                                position="relative"
                                top="-12px"
                                marginTop="0px"
                                textAlign="left"
                              >
                                {station.aqi <= 100 ? (
                                  <Text color={theme.colors.green[600]}>
                                    Good
                                  </Text>
                                ) : station.aqi > 100 ? (
                                  <Text color={theme.colors.yellow[600]}>
                                    Moderate
                                  </Text>
                                ) : (
                                  <Text color={theme.colors.red[600]}>
                                    Un-Heathy
                                  </Text>
                                )}
                              </Box>
                            </VStack>
                          </Flex>
                          <Button
                            height="48px"
                            width="100%"
                            colorScheme="blue"
                            onClick={e =>
                              this.openDrawer({
                                name: station.name,
                                id: station.id,
                              })
                            }
                          >
                            View Details
                          </Button>
                        </VStack>
                      </Box>
                    </Box>
                  );
                })}
              </Flex>
            </Box>
          ) : null}
        </Box>
        <footer>
          <Text
            fontSize="14px"
            fontFamily={theme.fonts[1]}
            textColor={theme.colors.blue[800]}
            textAlign="center"
            paddingTop="42px"
          >
            Let's make our Rajkot Clean and Green city.
          </Text>
          <Text
            fontSize="14px"
            fontFamily={theme.fonts[1]}
            textColor={theme.colors.blue[800]}
            textAlign="center"
            py="2px"
            paddingBottom="22px"
          >
            A project of Bhautik Chudasama.
          </Text>
        </footer>
        <IconButton
          colorScheme="blue"
          aria-label="Search database"
          icon={<Icon w="22px" h="22px" />}
          width="52px"
          height="52px"
          position="fixed"
          bottom="20px"
          right="20px"
          onClick={e => this.modelOpen(e)}
        />
        <Modal
          isOpen={this.state.utilities.hasModelOpen}
          onClose={e => this.modelClose()}
          scrollBehavior={'inside'}
        >
          <ModalOverlay />
          <ModalContent fontFamily={theme.fonts[0]}>
            <ModalHeader>Help & Privacy Policy</ModalHeader>
            <ModalCloseButton />
            <ModalBody fontFamily={theme.fonts[1]}>
              <Text>
                <b>What is AQI?</b>
              </Text>
              <Text>
                Think of the AQI(Air Quality Index) as a yardstick that runs
                from 0 to 500. The higher the AQI value, the greater the level
                of air pollution and the greater the health concern. For
                example, an AQI value of 50 or below represents good air
                quality, while an AQI value over 300 represents hazardous air
                quality.
              </Text>
              <Text paddingTop="14px">
                <b>User Accounts & Collection of Information</b>
              </Text>
              <Text>
                You are not required to create a user account or provide any
                personal information in order to visit the our website. The
                type, device type, time zone, language, Geo Location, and other
                information related to the manner in which you access our
                service.
              </Text>
              <Text paddingTop="14px">
                <b>Use of Information</b>
              </Text>
              <Text>
                We use your personal informations to provide better experience.
                We may do this for example, by collecting your Geo Location we
                provide your nearest location station Air Quality Index.
              </Text>
              <Text paddingTop="14px">
                <b>Sharing of Information</b>
              </Text>
              <Text>
                We share your device Id, IP address with Google Analytics and We
                do not share your Geo Location to any other parties. Your Geo
                Location is only store inside your device.
              </Text>
              ------------------
              <Text>
                This is a project of <b>Bhautik Chudasama</b>.
              </Text>
              <Text>Thanks to Rajkot Muncipal Corporation.</Text>
            </ModalBody>
          </ModalContent>
        </Modal>

        <Drawer
          onClose={e => this.onCloseDetailWeather(e)}
          isOpen={this.state.drawer.isOpen}
          size={'sm'}
          scrollBehavior="inside"
        >
          <DrawerOverlay>
            <DrawerContent>
              <DrawerHeader
                fontFamily={theme.fonts[0]}
                color={theme.colors.gray[600]}
              >
                {this.state.drawer.stationName}
              </DrawerHeader>
              <DrawerCloseButton />
              <DrawerBody>
                {this.state.drawer.hasDataFetching &&
                !this.state.drawer.hasFetched ? (
                  <Box marginTop="12px">
                    <Text
                      fontSize="12px"
                      fontFamily={theme.fonts[1]}
                      textColor={theme.colors.blue[800]}
                      paddingTop="6px"
                      textAlign="left"
                    >
                      STATISCTICS
                    </Text>
                    <VStack spacing="4px" align="left" marginTop="12px">
                      <Skeleton width="100%" height="22px" />
                      <Skeleton width="80%" height="22px" />
                      <Skeleton width="90%" height="22px" />
                      <Skeleton width="60%" height="22px" />
                    </VStack>
                    <VStack spacing="4px" align="left" marginTop="12px">
                      <Skeleton width="100%" height="22px" />
                      <Skeleton width="80%" height="22px" />
                      <Skeleton width="90%" height="22px" />
                      <Skeleton width="60%" height="22px" />
                    </VStack>
                    <VStack spacing="4px" align="left" marginTop="12px">
                      <Skeleton width="100%" height="22px" />
                      <Skeleton width="80%" height="22px" />
                      <Skeleton width="90%" height="22px" />
                      <Skeleton width="60%" height="22px" />
                    </VStack>
                    <VStack spacing="4px" align="left" marginTop="12px">
                      <Skeleton width="100%" height="22px" />
                      <Skeleton width="80%" height="22px" />
                      <Skeleton width="90%" height="22px" />
                      <Skeleton width="60%" height="22px" />
                    </VStack>
                  </Box>
                ) : null}

                {!this.state.drawer.hasFetching &&
                this.state.drawer.hasFetched ? (
                  <Box>
                    <Box>
                      <Flex>
                        <Text
                          fontFamily={theme.fonts[0]}
                          textColor={theme.colors.blue[800]}
                          fontSize="42px"
                        >
                          {this.state.drawer.aqi <= 100
                            ? '😇'
                            : this.state.drawer.aqi > 100
                            ? '😫'
                            : '😷'}{' '}
                          {this.state.drawer.aqi}{' '}
                        </Text>
                        <VStack marginTop="0" marginLeft="12px" align="left">
                          <Text
                            fontSize="18px"
                            fontFamily={theme.fonts[0]}
                            textColor={theme.colors.blue[800]}
                            paddingTop="8px"
                            textAlign="left"
                          >
                            AQI
                          </Text>
                          <Box
                            fontSize="13px"
                            fontFamily={theme.fonts[1]}
                            textColor={theme.colors.blue[800]}
                            position="relative"
                            top="-12px"
                            marginTop="0px"
                            textAlign="left"
                          >
                            {this.state.drawer.aqi <= 100 ? (
                              <Text color={theme.colors.green[600]}>Good</Text>
                            ) : this.state.drawer.aqi > 100 ? (
                              <Text color={theme.colors.yellow[600]}>
                                Moderate
                              </Text>
                            ) : (
                              <Text color={theme.colors.red[600]}>
                                Un-Heathy
                              </Text>
                            )}
                          </Box>
                        </VStack>
                      </Flex>
                    </Box>

                    <Box zIndex="99" marginTop="22px">
                      <Text
                        fontFamily={theme.fonts[1]}
                        textColor={theme.colors.gray[800]}
                        size="11px"
                      >
                        AQI Statistics
                      </Text>
                      <Box marginTop="16px" width={['90%', '100%']}>
                        <AreaChart
                          width={'100%'}
                          height={100}
                          data={this.state.drawer.aqiHistory}
                          gridlines={
                            <GridlineSeries
                              line={<Gridline direction="all" />}
                            />
                          }
                        />
                      </Box>
                    </Box>

                    <Box marginTop="42px">
                      <Text
                        fontFamily={theme.fonts[1]}
                        textColor={theme.colors.gray[800]}
                        size="11px"
                      >
                        CO Statistics
                      </Text>
                      <Box marginTop="12px">
                        <Flex align="center">
                          <Text
                            fontFamily={theme.fonts[0]}
                            textColor={theme.colors.blue[800]}
                            fontSize="42px"
                          >
                            {this.state.drawer.co}
                          </Text>
                          <Box width="10%"></Box>
                          <BarSparklineChart
                            width={'100%'}
                            height={42}
                            data={this.state.drawer.coHistory}
                          />
                        </Flex>
                      </Box>
                    </Box>

                    <Box marginTop="42px">
                      <Text
                        fontFamily={theme.fonts[1]}
                        textColor={theme.colors.gray[800]}
                        size="11px"
                      >
                        CO<sub>2</sub> Statistics
                      </Text>
                      <Box marginTop="12px">
                        <Flex align="center">
                          <Text
                            fontFamily={theme.fonts[0]}
                            textColor={theme.colors.blue[800]}
                            fontSize="42px"
                          >
                            {this.state.drawer.co2}
                          </Text>
                          <Box width="10%"></Box>
                          <BarSparklineChart
                            width={'100%'}
                            height={42}
                            data={this.state.drawer.co2History}
                          />
                        </Flex>
                      </Box>
                    </Box>

                    <Box marginTop="42px">
                      <Text
                        fontFamily={theme.fonts[1]}
                        textColor={theme.colors.gray[800]}
                        size="11px"
                      >
                        Humidity Statistics
                      </Text>
                      <Box marginTop="12px">
                        <Flex align="center">
                          <Text
                            fontFamily={theme.fonts[0]}
                            textColor={theme.colors.blue[800]}
                            fontSize="42px"
                          >
                            {this.state.drawer.humidity}
                          </Text>
                          <Box width="10%"></Box>
                          <BarSparklineChart
                            width={'100%'}
                            height={42}
                            data={this.state.drawer.humidityHistory}
                          />
                        </Flex>
                      </Box>
                    </Box>

                    <Box marginTop="42px">
                      <Text
                        fontFamily={theme.fonts[1]}
                        textColor={theme.colors.gray[800]}
                        size="11px"
                      >
                        Temperature Statistics (Celcius)
                      </Text>
                      <Box marginTop="12px">
                        <Flex align="center">
                          <Text
                            fontFamily={theme.fonts[0]}
                            textColor={theme.colors.blue[800]}
                            fontSize="42px"
                          >
                            {this.state.drawer.temprature}
                          </Text>
                          <Box width="10%"></Box>
                          <BarSparklineChart
                            width={'100%'}
                            height={42}
                            data={this.state.drawer.tempratureHistory}
                          />
                        </Flex>
                      </Box>
                    </Box>

                    <Box marginTop="42px">
                      <Text
                        fontFamily={theme.fonts[1]}
                        textColor={theme.colors.gray[800]}
                        size="11px"
                      >
                        Noise Statistics
                      </Text>
                      <Box marginTop="12px">
                        <Flex align="center">
                          <Text
                            fontFamily={theme.fonts[0]}
                            textColor={theme.colors.blue[800]}
                            fontSize="42px"
                          >
                            {this.state.drawer.noise}
                          </Text>
                          <Box width="10%"></Box>
                          <BarSparklineChart
                            width={'100%'}
                            height={42}
                            data={this.state.drawer.noiseHistory}
                          />
                        </Flex>
                      </Box>
                    </Box>

                    <Box marginTop="42px">
                      <Text
                        fontFamily={theme.fonts[1]}
                        textColor={theme.colors.gray[800]}
                        size="11px"
                      >
                        UV Statistics
                      </Text>
                      <Box marginTop="12px">
                        <Flex align="center">
                          <Text
                            fontFamily={theme.fonts[0]}
                            textColor={theme.colors.blue[800]}
                            fontSize="42px"
                          >
                            {this.state.drawer.uv}
                          </Text>
                          <Box width="10%"></Box>
                          <BarSparklineChart
                            width={'100%'}
                            height={42}
                            data={this.state.drawer.uvHistory}
                          />
                        </Flex>
                      </Box>
                    </Box>

                    <Box marginTop="42px">
                      <Text
                        fontFamily={theme.fonts[1]}
                        textColor={theme.colors.gray[800]}
                        size="11px"
                      >
                        NO Statistics
                      </Text>
                      <Box marginTop="12px">
                        <Flex align="center">
                          <Text
                            fontFamily={theme.fonts[0]}
                            textColor={theme.colors.blue[800]}
                            fontSize="42px"
                          >
                            {this.state.drawer.no}
                          </Text>
                          <Box width="10%"></Box>
                          <BarSparklineChart
                            width={'100%'}
                            height={42}
                            data={this.state.drawer.noHistory}
                          />
                        </Flex>
                      </Box>
                    </Box>

                    <Box marginTop="42px">
                      <Text
                        fontFamily={theme.fonts[1]}
                        textColor={theme.colors.gray[800]}
                        size="11px"
                      >
                        NO<sub>2</sub> Statistics
                      </Text>
                      <Box marginTop="12px">
                        <Flex align="center">
                          <Text
                            fontFamily={theme.fonts[0]}
                            textColor={theme.colors.blue[800]}
                            fontSize="42px"
                          >
                            {this.state.drawer.no2}
                          </Text>
                          <Box width="10%"></Box>
                          <BarSparklineChart
                            width={'100%'}
                            height={42}
                            data={this.state.drawer.no2History}
                          />
                        </Flex>
                      </Box>
                    </Box>
                  </Box>
                ) : null}
              </DrawerBody>
            </DrawerContent>
          </DrawerOverlay>
        </Drawer>
      </div>
    );
  }
}

export default Home;

